import Parsley from'parsleyjs';import renderTemplate from'cigna-frontend/js/util/template';import svgTemplate from'./views/svg.tpl.html';export default(function($form){$form.parsley({successClass:'input__validate--success',errorClass:'input__validate--error',classHandler:function classHandler(el){return el.$element.closest('.form__group');},errorsWrapper:'<div class="input__feedback input__feedback--error"></div>',errorTemplate:'<p class="input__message input__message--error"></p>',inputs:"".concat(Parsley.options.inputs,",[data-parsley-combine-inputs]")});$form.parsley().on('field:error',function(fieldInstance){var svg=renderTemplate(svgTemplate,{iconName:'exclamation-circle',iconColor:'digital-red',iconSize:'ml',imgPath:$form.data('form-img-path')});var feedbackBox=fieldInstance.$element.next('.input__feedback--error');if(!feedbackBox.find('svg').length){feedbackBox.prepend(svg);}});/*
  $form.parsley().on('field:success', fieldInstance => {
    const svg = renderTemplate(svgTemplate, {
      iconName: 'check-circle',
      iconColor: 'digital-green',
      iconSize: 'ml',
    });
    if (!fieldInstance.$element.next('svg').length) {
      fieldInstance.$element.after(svg);
    }
  });
  */});